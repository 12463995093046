import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navbarData, setNavbarData] = useState([]);

  const getNavbar = () => {
    axios(`https://navbar.saytqur.az/api/navitems/`)
      .then((res) => {
        setNavbarData(
          res.data.filter(
            (item) => item.label === "Page1" || item.label === "Page2"
          )
        );
      })
      .catch(() => {});
  };

  useEffect(() => {
    getNavbar();
  }, []);

  return (
    <div className="navbar">
      {navbarData?.map((item) => {
        return (
          <Link
            key={item.id}
            style={{
              display: item.is_active === false ? "none" : "",
            }}
            className="navbarItems"
            to={item.link}
          >
            {item.label}
          </Link>
        );
      })}
    </div>
  );
};

export default Navbar;
