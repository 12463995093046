import Navbar from "../components/Navbar";

const Dashboard = () => {
  return (
    <>
      <Navbar />
      <div className="pageContent">
        <h1>Page 2</h1>
      </div>
    </>
  );
};

export default Dashboard;
