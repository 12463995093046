import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <Routes>
      <Route path="/page1" element={<Home />} />
      <Route path="/page2" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/page1" replace />} />
    </Routes>
  );
}

export default App;
